import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import Config from 'Data';
import {
  Hero,
  HeroBig,
  PostList,
  HeroVideo,
  HeroFull,
  BackgroundImage,
  BackgroundVideo,
  SectionBackground,
  EventCalendarCard,
  EventCalendarList,
  FormAttend,
  FormShareEvent,
} from 'Components';

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const hero = data.index.page.error;
    const { banner } = data.banners;

    return (
      <Layout>
        <Helmet
          title={`404 | ${Config.brandBusiness} by ${Config.contact.name}`}
        />
        <HeroFull title={hero.title} vimeoId={hero.video}>
          {hero.subtitle}
        </HeroFull>
        <Container>
          <h3>
            You can return to the <Link to="/">Homepage</Link>.
          </h3>
        </Container>
      </Layout>
    );
  }
}

export default ErrorPage;

Container.propTypes = {
  center: PropTypes.object,
};

export const query = graphql`
  query ErrorPageQuery {
    index: pagesJson {
      page {
        error {
          title
          subtitle
          video
          heroImage {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
              }
            }
          }
        }
      }
    }
    banners: bannersJson {
      banner {
        attend {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        sponsor {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        sharing {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
    content: homeJson {
      featured {
        title
        copy
        image {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
  }
`;
